/**
 * 系统管理-角色管理-绑定菜单
 * luxinwen
 * 2022-08
 */
<template>
  <div>
    <Drawer class="drawer" :title="title" v-model="myValue" width="600" :before-close="beforeClose">
      <Tree ref="menuTree" :data="treeData" show-checkbox multiple></Tree>
      <div class="drawer-foot">
        <Button type="primary" @click="submit">提交</Button>
      </div>
      <Spin size="large" fix v-if="isLoading"></Spin>
    </Drawer>
  </div>
</template>

<script>
  import { MERCHANTS } from '@/util/enum';
  const formData = {
    roleId: '',
    roleName: '',
    merchantCode: '',
    list: []
  };

  export default {
    props: {
      value: {
        type: Boolean,
        default: false
      },
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    data() {
      return {
        myValue: this.value,  // 是否显示抽屉
        isInit: false,        // 是否初始化
        isLoading: false,     // 是否加载中
        formData: Object.assign({}, formData),
        backupData: {},
        treeData: []
      };
    },
    computed: {
      title() {
        return '绑定菜单：' + this.data.roleName;
      },
      isEqual() {
        return this.deepEqual(this.formData, this.backupData);
      }
    },
    methods: {
      /**
       * 获取数据
       */
      getData() {
        this.treeData = [
          {
            title: MERCHANTS[this.data.merchantCode],
            expand: true,
            children: []
          }
        ];
        this.$axios({
          url: this.$api.system.roleMenu,
          data: {
            roleId: this.data.roleId
          },
          beforeSend: () => {
            this.isLoading = true;
          }
        }).then(data => {
          this.formData = Object.assign({}, formData);
          Object.keys(this.formData).forEach(item => {
            if (this.data.hasOwnProperty(item)) {
              this.formData[item] = this.data[item];
            }
          });
          this.formData.list = data || [];
          this.backupData = this.deepCopy(this.formData);
          this.getMenuData();
        });
      },
      /**
       * 获取菜单数据
       */
      getMenuData() {
        this.$axios({
          url: this.$api.system.queryMenus,
          data: {},
          complete: () => {
            this.isLoading = false;
            this.isInit = true;
          }
        }).then(data => {
          let list = data || [];
          let menuRoot = [];  // 一级菜单数组
          let menuSub = []; // 子菜单数组（二级菜单和三级菜单）
          // 遍历接口返回数据，区分出一级菜单和子菜单
          list.forEach(item => {
            if (item.merchantCode === this.formData.merchantCode) {
              let parent = item.parentMenuId === 0 ? menuRoot : menuSub;
              parent.push({
                title: item.menuName,
                row: item,
                children: []
              });
            }
          });
          // 先处理子菜单，倒序排列，然后找出三级菜单（按钮级别）并放入对应的二级菜单中
          menuSub.reverse().forEach(item => {
            let index = menuSub.findIndex(parent => parent.row.menuId === item.row.parentMenuId);
            if (index > -1) {
              // 判断设置三级菜单勾选状态
              item.checked = this.formData.list.includes(item.row.menuId);
              menuSub[index].children.push(item);
            }
          });
          // 再把二级菜单放入对应的一级菜单中
          menuSub.forEach(item => {
            let index = menuRoot.findIndex(parent => parent.row.menuId === item.row.parentMenuId);
            if (index > -1) {
              // 判断二级菜单是否有子菜单，无的话则直接设置勾选状态，有的话则判断是否半选中状态
              if (item.children.length === 0) {
                item.checked = this.formData.list.includes(item.row.menuId);
              } else {
                let children = item.children.map(sub => sub.row.menuId);
                if (!this.includesOfArray(this.formData.list, children) && this.formData.list.includes(item.row.menuId)) {
                  item.indeterminate = true;
                }
              }
              menuRoot[index].children.push(item);
            }
          });
          // 最后把数据填充入菜单树
          menuRoot.reverse().forEach(item => {
            // 判断一级菜单是否有子菜单，无的话则直接设置勾选状态，有的话则判断是否半选中状态
            if (item.children.length === 0) {
              item.checked = this.formData.list.includes(item.row.menuId);
            } else {
              item.indeterminate = this.formData.list.includes(item.row.menuId);
            }
            item.expand = true;
            this.treeData[0].children.push(item);
          });
        });
      },
      /**
       * 判断数组1是否完全包含数组2元素
       */
      includesOfArray(arr1, arr2) {
        return arr2.every(val => arr1.includes(val));
      },
      /**
       * 关闭前的回调
       */
      beforeClose() {
        return new Promise((resolve, reject) => {
          if (!this.isInit) {
            // 如果未初始化时快速点击关闭，则不做处理
          } else if (this.isEqual) {
            this.myValue = false;
          } else {
            this.$confirm('数据变更后未保存，是否直接关闭？').then(() => {
              this.myValue = false;
            });
          }
        });
      },
      /**
       * 提交
       */
      submit() {
        let selected = this.$refs.menuTree.getCheckedAndIndeterminateNodes(); // 获取选中及半选节点
        let list = [];
        selected.forEach(item => {
          if (item.row) {
            list.push(item.row.menuId);
          }
        });
        this.formData.list = list;
        if (this.formData.list.length === 0) {
          this.notice('请选择要绑定的菜单');
          return false;
        }
        this.$confirm('确定要提交吗？').then(() => {
          let url = this.$api.system.bindMenu;
          let data = {
            roleId: this.data.roleId,
            menuIdList: this.formData.list
          };
          this.save(url, data);
        });
      },
      /**
       * 保存
       */
      save(url, data) {
        this.$axios({
          url,
          data,
          beforeSend: () => {
            this.isLoading = true;
          },
          complete: () => {
            this.isLoading = false;
          }
        }).then(data => {
          this.notice({
            type: 'success',
            desc: '提交成功'
          });
          this.myValue = false;
          this.$emit('success');
        });
      }
    },
    watch: {
      value(val) {
        this.myValue = val;
        if (this.myValue) {
          this.getData();
          // 隐藏页面滚动条
          document.querySelector('body').classList.add('hidden');
          // 重置内容域滚动条
          this.$nextTick(() => {
            let $drawer = document.querySelector('.drawer .ivu-drawer-body');
            if ($drawer) $drawer.scrollTo(0, 0);
          });
        } else {
          // 重置初始化状态
          this.isInit = false;
          // 恢复页面滚动条
          document.querySelector('body').classList.remove('hidden');
        }
      },
      myValue(val) {
        this.$emit('input', val);
      }
    }
  };
</script>