/**
 * 系统管理-角色管理
 * luxinwen
 * 2022-08
 */
<template>
  <div class="page">
    <div class="page-main">
      <div class="page-main-content" v-grant="'OSP_SYS_ROLE_SAVE'">
        <Button type="primary" @click="addData">新增角色</Button>
      </div>
      <div class="page-main-content">
        <sp-table :columns="filterColumns" :data="tableData" :loading="tableLoading">
          <template slot-scope="params" slot="operation">
            <Button @click="editData(params.row, true)" v-grant="'OSP_SYS_ROLE_VIEW'">查看</Button>
            <Button @click="editData(params.row, false)" v-grant="'OSP_SYS_ROLE_UPDATE'">编辑</Button>
            <Button @click="bindMenu(params.row)" v-grant="'OSP_SYS_ROLE_BIND'">绑定菜单</Button>
            <Button type="error" @click="removeData(params.row)" v-grant="'OSP_SYS_ROLE_DELETE'">删除</Button>
          </template>
        </sp-table>
      </div>
    </div>

    <edit-drawer v-model="drawerDisplay" :data="drawerData" :flag="drawerFlag" @success="getData"></edit-drawer>
    <bind-menu v-model="menuDisplay" :data="menuData" @success="getData"></bind-menu>
  </div>
</template>

<script>
  import editDrawer from './edit.vue';
  import bindMenu from './bind-menu.vue';
  import { MERCHANTS } from '@/util/enum';

  export default {
    components: {
      editDrawer,
      bindMenu
    },
    data() {
      return {
        tableColumns: [
          {
            title: '序号',
            type: 'index',
            width: 70,
            align: 'center'
          },
          {
            title: '角色名称',
            key: 'roleName'
          },
          {
            title: '所属系统',
            key: 'merchantCode',
            render: (h, params) => {
              return h('div', MERCHANTS[params.row.merchantCode] || params.row.merchantCode);
            }
          },
          {
            title: '更新人',
            key: 'updateUser'
          },
          {
            title: '更新时间',
            key: 'updateTime'
          },
          {
            title: '操作',
            slot: 'operation',
            align: 'center'
          }
        ],
        tableData: [],
        tableLoading: false,  // 表格查询状态
        drawerDisplay: false, // 是否显示编辑抽屉
        drawerData: {},       // 编辑抽屉数据
        drawerFlag: 'add',    // 编辑抽屉状态
        menuDisplay: false,   // 是否显示菜单
        menuData: {}          // 菜单数据内容
      };
    },
    computed: {
      filterColumns() {
        let val = [];
        let grant = [this.$grant.OSP_SYS_ROLE_VIEW, this.$grant.OSP_SYS_ROLE_UPDATE, this.$grant.OSP_SYS_ROLE_BIND, this.$grant.OSP_SYS_ROLE_DELETE];
        if (!this.checkGrant(grant)) {
          val = this.tableColumns.filter(item => item.slot !== 'operation');
        } else {
          val = this.tableColumns;
        }
        return val;
      }
    },
    created() {
      this.getData();
    },
    methods: {
      /**
       * 获取数据
       */
      getData() {
        this.$axios({
          url: this.$api.system.queryRoles,
          data: {},
          beforeSend: () => {
            this.tableLoading = true;
          },
          complete: () => {
            this.tableLoading = false;
          }
        }).then(data => {
          let list = data || [];
          // list.sort((a, b) => {
          //   return a.merchantCode.length - b.merchantCode.length;
          // });
          this.tableData = list.filter(item => item.merchantCode === 'OSP');
        });
      },
      /**
       * 获取表格行样式
       */
      rowClassName(row, index) {
        if (row.merchantCode !== 'OSP') {
          return 'disabled-row';
        } else {
          return '';
        }
      },
      /**
       * 新增数据
       */
      addData() {
        this.drawerFlag = 'add';
        this.drawerData = {
          merchantCode: 'OSP'
        };
        this.drawerDisplay = true;
      },
      /**
       * 查看、编辑数据
       */
      editData(row, onlyRoad = false) {
        this.drawerFlag = onlyRoad ? 'show' : 'edit';
        this.drawerData = row;
        this.drawerDisplay = true;
      },
      /**
       * 绑定菜单
       */
      bindMenu(row) {
        this.menuData = {
          roleId: row.roleId,
          roleName: row.roleName,
          merchantCode: row.merchantCode
        };
        this.menuDisplay = true;
      },
      /**
       * 删除数据
       */
      removeData(row) {
        this.$confirm(`确定要删除 [${row.roleName}] 吗？`).then(() => {
          this.$axios({
            url: this.$api.system.deleteRole,
            data: {
              roleId: row.roleId
            },
            loading: true
          }).then(data => {
            this.notice({
              type: 'success',
              desc: '删除成功',
            });
            this.getData();
          });
        });
      }
    }
  };
</script>